import React, {Fragment, useEffect, useState, useCallback} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {XIcon} from '@heroicons/react/outline'

import Footer from "./components/Footer";
import Header from "./components/Header";
import {graphql} from "gatsby";
import {filter, map, orderBy} from 'lodash';
import {Helmet} from "react-helmet";
import Banner from "./components/Banner";
import ListFilter from "./components/ListFilter";
import {includes} from 'lodash';
import ImageViewer from 'react-simple-image-viewer';


export default function Collectie({data, location}) {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)
    const [makeFilters, setMakeFilters] = useState([])
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const makes = data.cms.makes ? data.cms.makes : []
    const products = data.cms.products ? data.cms.products : []
    const filteredProducts = (makeFilters.length > 0) ? filter(products, (p) => includes(makeFilters, p.makeId)) : products
    const filteredProductImages = map(filteredProducts, p => p.imageUrl)

    useEffect(() => {
        if (location.state && location.state.make) {
            setMakeFilters([location.state.make.id])
        }
    }, []);

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    return (
        <div className="bg-gray-50">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Fashion Antero | Kledij</title>
            </Helmet>

            <Banner/>

            <Header/>

            <div>
                {/* Mobile filter dialog */}
                <Transition.Root show={mobileFiltersOpen} as={Fragment}>
                    <Dialog as="div" className="fixed inset-0 flex z-40 sm:hidden" onClose={setMobileFiltersOpen}>
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25"/>
                        </Transition.Child>

                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-6 flex flex-col overflow-y-auto">
                                <div className="px-4 flex items-center justify-between">
                                    <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                                    <button
                                        type="button"
                                        className="-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                        onClick={() => setMobileFiltersOpen(false)}
                                    >
                                        <span className="sr-only">Close menu</span>
                                        <XIcon className="h-6 w-6" aria-hidden="true"/>
                                    </button>
                                </div>

                                {/* Filters */}
                                <form className="mt-4">
                                    FILTERS
                                </form>
                            </div>
                        </Transition.Child>
                    </Dialog>
                </Transition.Root>

                <main>
                    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                        <div className="py-14 text-center">
                            <h1 className="text-4xl font-extrabold tracking-tight text-gray-900">Nieuwe collectie!</h1>
                            <p className="mt-4 max-w-3xl mx-auto text-base text-gray-500">
                                Ondek hier een greep van onze nieuwe collectie
                            </p>
                        </div>


                        <ListFilter filters={makeFilters} makes={makes} onFiltersChanged={(filters) => setMakeFilters(filters)}/>


                        {/* Product grid */}
                        <section aria-labelledby="products-heading" className="mt-8">
                            <h2 id="products-heading" className="sr-only">
                                Products
                            </h2>

                            <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:gap-x-8 mb-4">
                                {filteredProducts.map((product, index) => (
                                    <div key={product.id} className="group">
                                        {/*<div className="w-full aspect-w-1 aspect-h-1 rounded-lg overflow-hidden sm:aspect-w-2 sm:aspect-h-3">*/}
                                        <div className="w-full rounded-lg overflow-hidden">
                                            <img
                                                src={product.imageUrl}
                                                onClick={ () => openImageViewer(index) }
                                                className="w-full h-full object-center object-cover lg:group-hover:opacity-75"
                                            />
                                        </div>
                                        {/*<div className="mt-4 flex items-center justify-between text-base font-medium text-gray-900">*/}
                                        {/*    <h3>{product.name}</h3>*/}

                                        {/*</div>*/}

                                    </div>
                                ))}
                            </div>
                        </section>


                    </div>
                </main>

                {isViewerOpen && (

                    <ImageViewer
                        src={ filteredProductImages }
                        currentIndex={ currentImage }
                        disableScroll={ false }
                        closeOnClickOutside={ true }
                        onClose={ closeImageViewer }
                        sty
                    />

                )}

                <Footer/>
            </div>
        </div>
    )
}


export const query = graphql`
query {
  cms {
     makes {
      id
      name
     }
     products {
      id
      makeId
      imageUrl
     }
  }
}
`
